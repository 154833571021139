import {
    Configuration,
    AccountControllerApi, I18nControllerApi
} from "acc-rest-client";
import environments from "../utils/environments";
import {getAuthenticatedUser} from "../utils/authHelper";

class AccountRestClient {

    constructor() {
    }

    accessToken = () => {
        return getAuthenticatedUser()?.access_token;
    };

    configuration = () => {
        const openapiConfig = new Configuration();
        openapiConfig.baseOptions = {
            headers: { Authorization: 'Bearer ' + this.accessToken() },
        };
        openapiConfig.basePath = environments.accountsApiUrl;
        return openapiConfig;
    };

    accountsApi = () => {
        return new AccountControllerApi(this.configuration());
    };
    
    i18nApi = () => {
        return new I18nControllerApi(this.configuration())
    }
}

export default AccountRestClient;