import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import accountRestService from "../../services/acc-rest.service";
import postboxRestService from "../../services/postbox-rest.service";


const initialState = {
    isUserLoading: false,
    loggedInUser: null,
    currentSendLetter: null,
    letterHistory: []
}

export const getLoggedInUser: any = createAsyncThunk(
    "app/getLoggedInUser",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.loadMe();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const sendLetter: any = createAsyncThunk(
    "app/sendLetter",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await postboxRestService.writeLetter(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLetterHistory: any = createAsyncThunk(
    "app/loadLetterHistory",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await postboxRestService.letterHistory();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            console.log("Action called setAppState")
            console.log(state)
            console.log(action.payload)
            state = {...state, ...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoggedInUser.pending, (state) => {
                state.isLoggeInUserLoading = true;
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.loggedInUser = action.payload;
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLetterHistory.pending, (state) => {
                state.isLetterHistoryLoading = true;
            })
            .addCase(loadLetterHistory.fulfilled, (state, action) => {
                state.isLetterHistoryLoading = false;
                state.letterHistory = action.payload;
            })
            .addCase(loadLetterHistory.rejected, (state, action) => {
                state.isLetterHistoryLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(sendLetter.pending, (state) => {
                state.isSendLetterLoading = true;
            })
            .addCase(sendLetter.fulfilled, (state, action) => {
                state.isSendLetterLoading = false;
                state.currentSendLetter = action.payload;
            })
            .addCase(sendLetter.rejected, (state, action) => {
                state.isSendLetterLoading = false;
                state.errorPaylod = action.payload;
            })
    },
});

export const {setAppState} = appSlice.actions;

export default appSlice.reducer;