import {
    Configuration,
    LetterControllerApi
} from "postbox-rest-client";
import environments from "../utils/environments";
import {getAuthenticatedUser} from "../utils/authHelper";

class PostboxRestClient {

    constructor() {
    }

    accessToken = () => {
        return getAuthenticatedUser()?.access_token;
    };

    configuration = () => {
        const openapiConfig = new Configuration();
        openapiConfig.baseOptions = {
            headers: { Authorization: 'Bearer ' + this.accessToken() },
        };
        openapiConfig.basePath = environments.postboxApiUrl;
        return openapiConfig;
    };

    lettersApi = () => {
        return new LetterControllerApi(this.configuration());
    };
    
}

export default PostboxRestClient;