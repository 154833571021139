import {postboxRestClient} from "../index";

const writeLetter = async (data) => {
  const response = await postboxRestClient.lettersApi().newLetter(data.newLetter);
  return response.data;
};

const letterHistory = async (data) => {
  const response = await postboxRestClient.lettersApi().allMyLetters();
  return response.data;
};

const postboxRestService = {
  writeLetter,
  letterHistory
};

export default postboxRestService;