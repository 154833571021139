import React from "react";
import { Card, Typography, List, Row, Col, Progress } from "antd";
// import { UserOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import {UserOutlined, LogoutOutlined, EditFilled, MenuOutlined, LoginOutlined} from "@ant-design/icons";
import { useSelector } from "react-redux";
import {useAuth} from "react-oidc-context";
const { Title, Text } = Typography;

// Styles object to reduce inline styles
const styles = {
  layoutContent: {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    backgroundColor: "#F4EDE6"
  },
  headerCard: {
    color: "white",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    borderBottomLeftRadius: "200px",
    borderTopRightRadius: "0",
    borderTopLeftRadius: "0",
    borderBottomRightRadius: "0",
    background: "linear-gradient(to bottom, #634C3D, #513B2D)",
    padding: "50px",
    position: "relative",
    // zIndex: 1,
    margin: "0px"
  },
  contentCard: {
    borderRadius: "15px",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    margin: "10px 10px",
    position: "relative",
    // zIndex: 1,
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",

  },

  arabicText: {
    fontSize: "24px",
    marginTop: "10px",
  },

  backLink: {
    color: "white",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    padding: "10px",
    flexDirection: 'column',

  }
};



export default function Dashboard() {
  const { loggedInUser } = useSelector((state) => state.app);
  const auth = useAuth();

  const username = loggedInUser?.firstName;
  return (
    <div className="layout-content" style={styles.layoutContent}>
      <Card bordered={false} style={styles.headerCard}>
        <Row align="middle">
          <Col span={20}>
            <Title level={1} style={{ margin: 0, color: "white", padding: "10px" }}>Dashboard</Title>
            <Text style={{ margin: 0, color: "white", padding: "10px" }}>LETTER TO HUDHUR (ABA)</Text>
          </Col>
          {/* <Col span={2} style={{ textAlign: "right" }} >
            <Link
              to="/accounts" >
              <UserOutlined style={{ fontSize: "24px", color: "white",  }} disabled/>
            </Link>
          </Col> */}
          <Col span={4} style={{ textAlign: "right", padding: "10px" }}>
            {auth.isAuthenticated ?
                <Link to="/" onClick={() => {
                  auth.signoutRedirect({post_logout_redirect_uri: window.location.href})
                }}>
                  <LogoutOutlined style={{ fontSize: "24px", color: "white" }} />
                </Link>
                :
                <Link to="/" onClick={() => {
                  auth.signinRedirect();
                }}>
                  <LoginOutlined style={{ fontSize: "24px", color: "white" }} />
                </Link>
            }
          </Col>
        </Row>
        <Text style={{ ...styles.arabicText, ...styles.backLink }}>السلام علیکم {username}</Text>
      </Card>

      <Card bordered={false} style={{ ...styles.contentCard }}>
        <div style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
            <Text strong>24 DAYS</Text>
            <Text type="secondary" style={{ float: "right" }}>Time to next Letter</Text>
            <Progress percent={80} showInfo={false} strokeColor="#6B4F4F" />
          </div>

        </div>
      </Card>
      <Row gutter={[16, 16]} style={{ margin: '20px 20px' }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Link to="/letter" style={{ textDecoration: 'none' }}>
            <Card
              bordered={false}
              hoverable
              style={{
                ...styles.contentCard,
                height: '120px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '30px'
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <EditFilled style={{ fontSize: '40px', marginBottom: '10px', color: '#634C3D' }} />
                <Text strong style={{ display: 'block', fontFamily: "'HvDTrial Brandon Grotesque', sans-serif", fontSize: '20px' }}>New Letter</Text>
              </div>
            </Card>
          </Link>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>

          <Link to="/history" style={{ textDecoration: 'none' }}>
            <Card
              bordered={false}
              hoverable
              style={{
                ...styles.contentCard,
                height: '120px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '30px'
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <MenuOutlined style={{ fontSize: '40px', marginBottom: '10px', color: '#634C3D' }} />
                <Text strong style={{ display: 'block', fontFamily: "'HvDTrial Brandon Grotesque', sans-serif", fontSize: '20px' }}>History</Text>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
}