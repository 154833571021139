import React, { useState } from "react";
import {
    Card,
    Typography,
    Select,
    Input,
    Button,
    Row,
    Col,
    message,
} from "antd";
import {
    MailOutlined,
    UserOutlined,
    DownOutlined,
    CreditCardOutlined,
    HistoryOutlined
} from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { GlobalOutlined } from '@ant-design/icons';
import { useAuth } from "react-oidc-context";
import { API_BASE_URI } from '../constants'
import {useDispatch, useSelector} from "react-redux";
import {sendLetter} from "../store/app/app.slice";
import {NewLetterDto} from "postbox-rest-client";

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

// Note: You need to import these fonts in your global CSS or index.html
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@800&display=swap');
// For HvDTrial Brandon Grotesque, you might need to use a custom font loader or include it in your project assets

export default function LetterPage() {
    const dispatch = useDispatch();
    const [category, setCategory] = useState(null);
    const [content, setContent] = useState("");
    const [language, setLanguage] = useState(null);

    const sendNewLetter = () => {
        const newLetter: NewLetterDto = {
            content: content,
            language: language,
            category: category
        }
        
        dispatch(sendLetter({finalData: {newLetter}, callbackSuccess: () => {
            // Reset the fields
            setCategory(null);
            setContent("");
            setLanguage(null);

            // Show a success message
            message.success("Letter saved successfully!");
        },
        callbackError: (error) => {
            console.error('Error saving letter:', error);
            message.error("Failed to save letter. Please try again.");
        }
        
        }))
    } 
    
    return (
        <div className="layout-content" style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
            <Row justify="center" align="middle" style={{ flex: 1 }}>
                <Col xs={24} sm={22} md={20} lg={18} xl={16}>
                    <Card bordered={false} className="criclebox h-full" style={{ padding: "32px" }}>
                        <Title
                            level={3}
                            style={{
                                fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                                fontSize: "30.74px",
                                fontWeight: 700,
                                lineHeight: "43.95px",
                                textAlign: "left",
                                marginBottom: "16px",
                                color: "#6B4F4F"
                            }}
                        >
                            Send a Letter
                        </Title>
                        <Text
                            style={{
                                display: "block",
                                fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                                fontSize: "17px",
                                fontWeight: 700,
                                lineHeight: "24.31px",
                                textAlign: "left",
                                marginBottom: "24px",
                                color: "#96A1BD"
                            }}
                        >
                            Max. 100 words allowed. A letter pad will be included automatically.
                        </Text>
                        <Select
                            style={{
                                width: "100%",
                                marginBottom: "24px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                            }}
                            placeholder={<span style={{
                                fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                                fontSize: "18px",
                                fontWeight: 700,
                                lineHeight: "15.73px",
                                color: "#494646",
                                opacity: 0.5
                            }}>
                                Choose Category
                            </span>}
                            dropdownStyle={{ borderRadius: "6px" }}
                            suffixIcon={<DownOutlined style={{ color: "#56B3B3", fontWeight: "bold" }} />}
                            value={category}
                            onChange={setCategory}
                        >
                            <Option value="category1">Category 1</Option>
                            <Option value="category2">Category 2</Option>
                            <Option value="category3">Category 3</Option>
                        </Select>
                        <Select
            style={{
                width: "100%",
                marginBottom: "24px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
            }}
            placeholder={<span style={{
                fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "15.73px",
                color: "#494646",
                opacity: 0.5
            }}>
                Choose Language
            </span>}
            dropdownStyle={{ borderRadius: "6px" }}
            suffixIcon={<GlobalOutlined style={{ color: "#56B3B3", fontWeight: "bold" }} />}
            value={language}
            onChange={setLanguage}
        >
            <Option value="english">English</Option>
            <Option value="urdu">اردو (Urdu)</Option>
        </Select>
                        <Card
                            bordered={false}
                            className="criclebox"
                            style={{ marginBottom: "24px" }}
                        >
                            {/* ... existing title ... */}
                            <TextArea
                                placeholder="Type..."
                                autoSize={{ minRows: 15, maxRows: 100 }}
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                                    border: "none",
                                    borderRadius: "6px",
                                    padding: "12px",
                                    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                                    fontSize: "15px",
                                    fontWeight: 400,
                                    lineHeight: "15.73px"
                                }}
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                            />
                        </Card>

                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <Button
                                type="primary"
                                size="large"
                                style={{
                                    marginBottom: "24px",
                                    backgroundColor: "#6B4F4F",
                                    borderColor: "#6B4F4F",
                                    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                                    fontSize: "32px",
                                    fontWeight: 200,
                                    lineHeight: "30.76px",
                                    textAlign: "center",
                                    height: "auto",
                                    padding: "10px 0",
                                    borderRadius: "20px",
                                    width: "200px",
                                    maxWidth: "80%",
                                }}
                                onClick={sendNewLetter}
                            >
                                send
                            </Button>
                        </div>
                    
                    </Card>
                </Col>
            </Row>
            {/* Updated menu bar */}
            <div style={{
                position: 'fixed',
                bottom: 20,
                left: 0,
                right: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '15px 20px',
                backgroundColor: 'white',
                boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.15)',
                zIndex: 1001 // Increased z-index to ensure it's above the card
            }}>
                <div style={{ width: '12px' }}></div>
                <Link to="/">
                    <CreditCardOutlined 
                        style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer' }} 
                    />
                </Link>
                <Link to="/history">
                    <HistoryOutlined 
                        style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer' }} 
                    />
                </Link>
            </div>
        </div>
    );
}