import React, {useEffect } from "react";
import { Card, Typography, List, Row, Col, Progress, message, Spin } from "antd";
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {loadLetterHistory} from "../store/app/app.slice";

const { Title, Text } = Typography;

// Styles object to reduce inline styles
const styles = {
  layoutContent: {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    backgroundColor: "#F4EDE6"
  },

headerCard: {
  color: "white",
  fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
  borderBottomLeftRadius: "200px",
  borderTopRightRadius: "0",
  borderTopLeftRadius: "0",
  borderBottomRightRadius: "0",
  background: "linear-gradient(to bottom, #634C3D, #513B2D)",
  padding: "50px",
  position: "relative",
  zIndex: 1,
  margin: "0px"
},
  contentCard: {
    borderRadius: "15px",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    margin: "-50px 40px 40px",
    position: "relative",
    zIndex: 2,
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    flex: 1,
    overflowY: "auto",
    msOverflowStyle: "none",  // IE and Edge
    scrollbarWidth: "none",  // Firefox
  },
  scrollbarStyles: {
    "&::-webkit-scrollbar": {
      display: "none",  // Chrome, Safari and Opera
    },
  },

  backLink: {
    color: "white",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    padding: "10px",
    fontFamily: "'Arial', sans-serif",
    fontSize: "16px",
    fontWeight: "bold",flexDirection: 'column',
  }
};

export default function History() {
  const dispatch = useDispatch();
  const { letterHistory, isLetterHistoryLoading } = useSelector((state) => state.app);
  
  useEffect(() => {
    dispatch(loadLetterHistory({}))
  }, []);

  
  const handleItemClick = (item) => {
      message.info(`You clicked on Letter no. ${item.number} from ${item.date}`);
  };
        return (
          <div className="layout-content" style={styles.layoutContent}>
            <Card bordered={false} style={styles.headerCard}>
              <Row align="middle">
                <Col span={20}>
                  <Title level={1} style={{ margin: 0, color: "white", padding: "10px" }}>History</Title>
                  <Text style={{ margin: 0, color: "white", padding: "10px" }}>LETTER TO HUDHUR (ABA)</Text>
                </Col>
                <Col span={4} style={{ textAlign: "right" }}>
                {/* <Link
                to="/accounts">
                  <UserOutlined style={{ fontSize: "24px", color: "white" }} />
                </Link> */}
                </Col>
              </Row>
              <Link to="/" style={styles.backLink}>
                 Back to Dashboard
              </Link>
            </Card>
      
            <Card bordered={false} style={{...styles.contentCard, ...styles.scrollbarStyles}}>
                <div style={{ padding: "20px" }}>
                    <div style={{ marginBottom: "20px" }}>
                        <Text strong>24 DAYS</Text>
                        <Text type="secondary" style={{ float: "right" }}>Time to next Letter</Text>
                        <Progress percent={80} showInfo={false} strokeColor="#6B4F4F" />
                    </div>
                    {isLetterHistoryLoading ? (
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%', // Ensure the container takes full height
                          width: '100%',  // Ensure the container takes full width
                        }}>
                       <Spin style={{alignItems:"center"}} indicator={<LoadingOutlined style={{ 
            fontSize: 48,
            color: '#808080' // This sets the color to a medium grey
          }}  spin />} />
                       </div>
                    ) : (
                        <List
                            dataSource={letterHistory}
                            renderItem={item => (
                                <List.Item 
                                    onClick={() => handleItemClick(item)}
                                    style={{ 
                                        padding: "10px 0", 
                                        borderBottom: "1px solid #f0f0f0",
                                        cursor: "pointer",
                                        transition: "background-color 0.3s",
                                    }}
                                    hoverable
                                >
                                    <Text>Letter no. {item.id}</Text>
                                    <Text type="secondary">{new Date(item.createdAt).toLocaleDateString()}</Text>
                                </List.Item>
                            )}
                        />
                    )}
                </div>
            </Card>
          </div>
        );
      }