/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import 'antd/dist/antd.variable.min.css';
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import {ConfigProvider} from "antd";
import AccessDeniedPage from "./pages/AccessDeniedPage";
import ProtectedRoute from "./navigation/Routes/ProtectedRoute";
import OIDCLogin from "./components/login/OIDCLogin";
import PageNotFound from "./pages/PageNotFound";
import {useDispatch, useSelector} from "react-redux";
import Dashboard from "./pages/Dashboard";
import LetterPage from "./pages/LetterPage";
import History from "./pages/History";
import environments from "./utils/environments";

ConfigProvider.config({
    theme: {
        primaryColor: '#755B48'
    },
});

function App() {
        return (
            <OIDCLogin forceLoginRedirect={true}>
                <div className="App">
                    <Switch>
                        <Route exact path="/" children={<ProtectedRoute element={<Dashboard/>}/>}/>
                        <Route exact path="/letter" children={<ProtectedRoute element={<LetterPage/>}/>}/>
                        <Route exact path="/history" children={<ProtectedRoute element={<History/>}/>}/>
                        <Route exact path="/error" children={<AccessDeniedPage/>}/>
                        <Route exact path='/profile' component={() => {
                            window.location.href = environments.accountsAppUrl + '/profile';
                            return null;
                        }}/>
                        <Route path="/" children={<PageNotFound/>}/>
                    </Switch>
                </div>
            </OIDCLogin>
        );
}

export default App;
